@import '_mixins.scss';

.font-clanot-book {
  font-family: $font-family-main;
}

.font-clanot-news {
  font-family: $font-family-news;
}

.font-clanot-medium {
  font-family: $font-family-medium;
}

.font-clanot-bold {
  font-family: $font-family-bold;
}

.font-clanot-ultra {
  font-family: $font-family-ultra;
}

.font-clanot-black {
  font-family: $font-family-black;
}

.btn {
  @apply border-2;
  @apply mr-4;
  @apply mb-2;
  @apply px-8;
  @apply py-5.5;
  @apply inline-block;
  @apply text-sm;
  transition: all 0.3s;
  font-family: $font-family-black;

  @media (min-width: theme('screens.md')) {
    @apply text-base;
  }

  &:hover,
  &:focus,
  &:active,
  &.selected {
    @apply no-underline;
  }

  &:disabled {
    @apply opacity-50;
    @apply cursor-default;
  }
}

.btn-primary {
  @apply shadow-3xl;
  transition: all 0.3s;
  color: $white;
  background-color: $primary;
  border-color: $primary;
  font-family: $font-family-black;

  &:hover:enabled {
    color: $primary;
    border-color: $primary;
    background: $white;
  }
}

.btn-secondary {
  @apply shadow-3xl;
  color: $primary;
  background-color: white;
  border-color: $primary;
  font-family: $font-family-black;
  transition: all 0.3s;

  &:hover svg{
    color: white;
  }

  &:hover,
  &:focus,
  &:active,
  &.selected {
    @apply border-2;
    @apply no-underline;
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
}

.btn-white {
  font-family: $font-family-black;
  transition: all 0.3s;
  @apply text-dark;
  @apply bg-white;
  @apply hover:text-dark;
  @apply hover:bg-light-blue;
  @apply focus:bg-light-blue;
}

.btn-light-blue {
  font-family: $font-family-black;
  transition: all 0.3s;
  @apply text-dark;
  @apply bg-light-blue;
  @apply hover:bg-white;
  @apply focus:bg-white;
}

.btn-standard {
  color: $dark;
  border-color: $dark;
}

.btn-standard-fill {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}

.btn-alternate-fill {
  color: $primary;
  background-color: $white;
  border-color: $primary;
}

.btn-no-margin {
  margin: 0;
}

.btn-large,
.btn-large:hover,
.btn-large:focus {
  @media (min-width: theme('screens.lg')) {
    padding: 30px;
  }
}

.filter {
  @apply mr-3;
  @apply mb-3;
  @apply bg-white;
  line-height: 20px;
  border-radius: 30px;
  border: 1px solid $lightGray;
  color: $dark;
  padding: 10px 20px 8px;

  @media (min-width: theme('screens.sm')) {
    @apply text-sm;
    @apply mr-3;
    @apply mb-3;
    line-height: 20px;
  }

  &:hover,
  &:active,
  &:focus,
  &.selected {
    font-weight: bold;
  }

  &:active,
  &.selected {
    color: $white;
    background: $red;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.select-filter {
  position: relative;
  max-width: 295px;
  width: 100%;
  font-family: "ClanOT-Black", sans-serif;

  &::before {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23dc151d' fill-rule='evenodd' d='M12.293 4.293a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L8 8.586l4.293-4.293z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 62.5%;
    display: block;
    position: absolute;
    right: 10px;
    top: 2px;
    bottom: 0;
    width: 40px;
    height: 100%;
    pointer-events: none;
    cursor: pointer;
    z-index: 10;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 1.375rem 3.125rem 1.375rem 2rem;
    @apply border-2;
    @apply border-medium-light-grey;
    border-radius: 3rem;
    width: 100%;
  }

  &--full-width {
    max-width: none;
  }
}

.background-link {
  @include underline-bottom-to-top;

  &--dark {
    @include underline-bottom-to-top($dark, $white, $dark);
  }

  &--lightergray {
    @include underline-bottom-to-top($dark, $dark, $lighterGray);
  }

  &--no-underline {
    background-size: 0 2px;
  }
}

.load-more-btn {
  p {
    background: linear-gradient(to bottom, $dark 0%, $dark 100%);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 0px 2px;
    transition: background-size 0.2s;
    padding-bottom: 2px;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    p {
      background-size: 100% 2px;
    }
    .bounce {
      animation: bounce 2s infinite;
    }
  }
}

.btn-animate-arrow {
  &:hover,
  .group:hover & {
    .bounce {
      animation: bounceDown 2.5s infinite;
    }
  }
}

.btn-animate-arrow-right {
  &:hover,
  .group:hover & {
    .bounce {
      animation: bounceRight 2.5s infinite;
    }
  }
}

.btn-animate-arrow-left {
  &:hover,
  .group:hover &  {
    .bounce {
      animation: bounceLeft 2.5s infinite;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounceRight {
  0%,
  20%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(12px);
  }
  60% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(5px);
  }
}

@keyframes bounceLeft {
  0%,
  20%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-12px);
  }
  60% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-5px);
  }
}

@keyframes bounceDown {
  0%,
  20%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(5px);
  }
  80% {
    transform: translateY(-5px);
  }
}

.bg-blue {
  background-color: $lightBlue;
}

.bg-gray {
  background-color: $lightGray;
}

.bg-beige {
  background: $darkBeige;
}

.basis-full {
  flex-basis: 100%;
}

.link-arrow-before,
.link-arrow-after {
  display: inline-block;
  position: relative;
  font-family: $font-family-news;
  border-bottom: 1px solid #000;

  &:hover {
    font-weight: bold;
  }
}

.link-arrow-before {
  padding-left: 82px;

  &:hover::before {
    animation: bounceRight 2s infinite;
  }

  &::before {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg id='Component_226_5' data-name='Component 226 – 5' xmlns='http://www.w3.org/2000/svg' width='32.76' height='12.595' viewBox='0 0 32.76 12.595'%3E%3Crect id='Rectangle_4694' data-name='Rectangle 4694' width='2' height='8.81' rx='1' transform='translate(32.75 6.23) rotate(135)' fill='%23333'/%3E%3Crect id='Rectangle_4695' data-name='Rectangle 4695' width='2' height='8.81' rx='1' transform='translate(31.346 4.951) rotate(45)' fill='%23333'/%3E%3Crect id='Rectangle_4696' data-name='Rectangle 4696' width='2' height='31.004' rx='1' transform='translate(31.004 5.363) rotate(90)' fill='%23333'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 32px 13px;
    height: 13px;
    width: 32px;
    position: absolute;
    left: 0;
    top: 8px;
  }
}

.link-arrow-after {
  padding-right: 40px;

  &:hover::after {
    animation: bounceRight 2s infinite;
  }

  &::after {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg id='Arrow_-_straight' data-name='Arrow - straight' xmlns='http://www.w3.org/2000/svg' width='22.126' height='16.585' viewBox='0 0 22.126 16.585'%3E%3Cg id='Group_1696' data-name='Group 1696' transform='translate(0 16.627) rotate(-90)'%3E%3Cpath id='Path_8680' data-name='Path 8680' d='M0,0,2.622-.033,2.477,11.516l-2.622.033Z' transform='translate(8.358 21.986) rotate(-135)' fill='%23333'/%3E%3Cpath id='Path_8681' data-name='Path 8681' d='M.047.011,2.669.044l.145,11.549L.192,11.561Z' transform='translate(10.229 20.144) rotate(135)' fill='%23333'/%3E%3Crect id='Rectangle_5170' data-name='Rectangle 5170' width='2.655' height='19.727' transform='translate(9.575 19.727) rotate(180)' fill='%23333'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 22px 16px;
    height: 16px;
    width: 22px;
    position: absolute;
    right: 0;
    bottom: 5px;
  }
}

// Dotted border with increased spacing between dots
.dotted-border-bottom {
  max-width: 0px;
  &:after {
    content:".......................................................................";
    font-size: 60px;
    line-height: 0px;
    letter-spacing: 10px; /* Use letter-spacing to increase space between dots*/
    color: $darkBlue;
    }
}

.border-primary {
  border-color: $primary;
}

.border-gray {
  border-color: $gray;
}