$font-family-main: "ClanOT-Book", sans-serif;
$font-family-news: "ClanOT-News", sans-serif;
$font-family-medium: "ClanOT-Medium", sans-serif;
$font-family-bold: "ClanOT-Bold", sans-serif;
$font-family-ultra: "ClanOT-Ultra", sans-serif;
$font-family-black: "ClanOT-Black", sans-serif;

$red: #dc151d;
$primary: $red;
$dark: #333;
$white: #fff;
$gray: #ededed;
$lightGray: #e0e4e8;
$mediumLightGray: '#DCE3EA';
$lighterGray: #f2f2f2;
$darkGray: #a3a3a3;
$lightBlue: #f0f4f8;
$lightBlueGray: #495564;
$lightGreen: #d6e1e1;
$beige: #d7c2a3;
$darkBeige: #FEC87B;
$darkBlue: #0F2331;
