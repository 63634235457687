@import '../../../styles/variables';

.videoWrapper {
  overflow: hidden;
  height: 0;
  position: relative;
  padding-top: 52.5%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
}
