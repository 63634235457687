@import '../../../styles/variables';
@import '../../../styles/mixins';

.container {
  margin-top: 80px;
  margin-bottom: 50px;

  @media (min-width: theme('screens.lg')) {
    margin-top: 50px;
  }
}
