@import '_mixins.scss';
@import '_variables.scss';

.subMenuLink{
  border-bottom: solid 4px transparent;
}

.subMenuLink:hover {
  @apply font-bold;
}

.subMenuActiveLink{
  border-bottom: solid 4px $red;
  font-family: $font-family-bold;
}

.header {
  height: 3.75rem;
}

.headerSearch {
  width: 0;
  max-height: 20px;
  padding-left: 0;
  transition: all 0.2s linear;
  position: relative;
  z-index: -1;
}

.headerSearchExpand {
  width: 100%;
  padding-left: 0.75rem;
  z-index: 0;
}

.headerButton {
  &:hover {
    @apply bg-light-blue;
  }
}

.headerInput {
  background: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $dark;
  }
}

.membershipDropdown{
  width: 100%;
  max-width: 20rem;
}

.notificationBadge {
  padding: 4px 6px 2px 6px;
  margin: 3px 3px 0 0;
}
