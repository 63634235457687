@import "./_variables.scss";
@import "./utilities.scss";
@import "_mixins.scss";
@import "../common/styles/imageCarousel";
@import "./form.scss";

@font-face {
  font-family: "ClanOT-Book";
  src: url("../fonts/ClanOT-Book.otf");
}

@font-face {
  font-family: "ClanOT-News";
  src: url("../fonts/ClanOT-News.otf");
}

@font-face {
  font-family: "ClanOT-Medium";
  src: url("../fonts/ClanOT-Medium.otf");
}

@font-face {
  font-family: "ClanOT-Bold";
  src: url("../fonts/ClanOT-Bold.otf");
}

@font-face {
  font-family: 'ClanOT-Ultra';
  src: url('../fonts/ClanOT-Ultra.otf');
}

@font-face {
  font-family: 'ClanOT-Black';
  src: url('../fonts/ClanOT-Black.otf');
}

html {
  font-size: 16px;
  font-family: $font-family-main;
}

h1,
.h1 {
  @apply text-2xl;
  @apply mb-1;

  @media (min-width: theme("screens.lg")) {
    @apply text-3xl;
  }
}

h2,
.h2 {
  @apply text-xl;
  @apply mb-1;

  @media (min-width: theme("screens.lg")) {
    @apply text-2xl;
  }
}

h3,
.h3 {
  @apply text-xl;
  @apply mb-1;
}

.bate-container {
  width: 100%;
  max-width: 1280px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.heading-underline {
  position: relative;
  @apply pb-2;

  &:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #000;
    bottom: 0;
    width: 40px;
    left: 0;
  }
}

.text-center {
  .heading-underline {
    &:after {
      right: 0;
      margin: auto;
    }
  }
}

.heading-underline-right {
  border-bottom: 1px solid;
  padding-bottom: 5px;
  padding-right: 15px;
  border-image: linear-gradient(to right, white calc(100% - 40px), #000 40px)
    100% 1;
}

button:focus {
  outline: none;
}

button:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  border-radius: inherit;
}

a.arrow-link {
  transition: 0.3s all;
  
  &:hover {
    @apply text-bate-red;
  }
}


.body {
  h2,
  h3 {
    margin-top: 30px;

    &:first-child {
      @apply mt-0;
    }
  }

  h2, .h2,
  h3, .h3 {
    @apply font-clanot-black;
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not(.btn) {
    font-family: $font-family-news;
    color: $red;
    text-decoration: none;
    padding-top: 2px;
    @include underline-bottom-to-top;

    &:active,
    &:focus {
      background-color: $primary;
      color: $white;
    }

    &.dark {
      @include underline-bottom-to-top($darkBlue, $white, $darkBlue);
    }
  }

  .post-reference a {
    @include underline-bottom-to-top($dark, $white, $dark);

    &:active,
    &:focus {
      background-color: $dark;
      color: $white;
    }
  }

  a {
    img {
      opacity: 1;
    }

    &:hover {
      img {
        opacity: 0.8;
        transition: opacity 0.1s ease-in-out;
      }
    }
  }

  ul,
  ol {
    list-style: none;
    margin-left: 1rem;

    li {
      position: relative;
      padding-left: 1.2rem;
      margin-bottom: 0.5rem;
    }

    @media (min-width: theme("screens.md")) {
      margin-left: 2rem;
    }
  }

  ul {
    li {
      &:before {
        content: "";
        position: absolute;
        left: -0.35rem;
        top: 0.4rem;
        height: 0.5rem;
        width: 0.5rem;
        background-color: $dark;
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style: decimal;
    margin-left: 1.7rem;

    li {
      padding-left: 0.5rem;

      &:before {
        content: "";
      }
    }

    @media (min-width: theme("screens.md")) {
      margin-left: 2.8rem;
    }
  }

  .border {
    &-bottom {
      &-dark {
        border-bottom: 2px solid $dark;
      }
    }
  }
}

.bate-link {
  font-weight: calc(3*3);
  font-family: $font-family-news;
  color: $red;
  text-decoration: none;
  padding-top: 2px;
  @include underline-bottom-to-top;

  &:active,
  &:focus {
    background-color: $primary;
    color: $white;
  }

  &.dark {
    @include underline-bottom-to-top($darkBlue, $white, $darkBlue);
  }
}

q,
blockquote {
  @apply block;
  @apply text-lg;
  @apply relative;
  @apply my-8;
  @apply px-6;
  @apply py-4;
  @apply pl-10;

  &:before {
    content: '';
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg id='Component_132_1' data-name='Component 132 – 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 83.442 77.836'%3e%3ccircle id='Ellipse_443' data-name='Ellipse 443' cx='15' cy='15' r='15' fill='%23dc151d'/%3e%3cpath id='Path_8378' data-name='Path 8378' d='M15,0c8.284,0,15,14.551,15,32.5S23.284,65,15,65c-3.695,0,3.354-11.545,2.949-26.1C17.46,21.334,10.411,0,15,0Z' transform='translate(4.735 -0.707) rotate(8)' fill='%23dc151d'/%3e%3ccircle id='Ellipse_444' data-name='Ellipse 444' cx='15' cy='15' r='15' transform='translate(49 10)' fill='%23dc151d'/%3e%3cpath id='Path_8379' data-name='Path 8379' d='M15,0c8.284,0,15,14.551,15,32.5S23.284,65,15,65c-3.695,0,3.354-11.545,2.949-26.1C17.46,21.334,10.411,0,15,0Z' transform='translate(53.734 9.293) rotate(8)' fill='%23dc151d'/%3e%3c/svg%3e ");
    height: 39px;
    width: 42px;
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
  }

  @media (min-width: theme('screens.lg')) {
    @apply py-3;
    padding-left: 75px;

    &:before {
      height: 52px;
      width: 55px;
    }
  }
}

@keyframes wiggle {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(5px);
  }
  20% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(5px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.wiggle {
  animation: wiggle 1s ease-in-out alternate;
}

.react-datepicker__triangle {
  left: -50% !important;
}

.srv-validation-message{
  color: $red
}

body.ReactModal__Body--open  {
  overflow: hidden;
  height: 100vh;
}