@import '../../../styles/variables';

.figure {
  margin-top: 50px;
  margin-bottom: 50px;
}

.figcaption {
  border-right-color: $primary;
  border-right-width: 5px;
  font-family: $font-family-news;

  @media (min-width: theme('screens.lg')) {
    border-right-width: 6px;
  }
}

.noMargin {
  margin: 0;
}
