@import '../../styles/variables';

//Override default styling

.banner-carousel {
  .slick-list {
    figure {
      height: 256px;

      @media (min-width: theme('screens.lg')) {
        height: 470px;
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .slick-dots {
    top: 200px;
    bottom: auto;

    @media (min-width: theme('screens.lg')) {
      top: 420px;
    }
  }
}

.slick-dots {
  li {
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    margin: 0 0.4rem;

    &.slick-active {
      button:before {
        color: $beige;
        background-color: $beige;
      }
    }

    button {
      width: 100%;
      height: 100%;
      color: $white;
      @apply rounded-full;

      &:before {
        opacity: 0.8;
        width: 100%;
        height: 100%;
        border: 1px solid $beige;
        @apply rounded-full;
        color: $white;
        background-color: $white;
      }
    }
  }
}

.slick-arrow {
  @apply rounded-full;
  width: auto;
  height: auto;
  background: $dark;
  color: $white;
  transform: none;
  font-size: 35px;
  z-index: 10;
  position: absolute;
  top: auto;
  bottom: -28px;
  padding: 10px;

  &:hover,
  &:focus {
    background: $dark;
    color: $white;
  }

  &:hover {
    font-size: 40px;
  }

  &::before {
    content: '';
  }
}
.slick-next {
  right: 15px;
}
.slick-prev {
  right: 85px;
  left: auto;
}
.slick-disabled {
  color: #afafaf;
  @apply text-base;
  padding: 10px;
  bottom: -21px;

  &.slick-next {
    right: 30px;
  }
}

.slick-slide {
  div {
    vertical-align: bottom;
  }
}

.project-carousel,
.blog-carousel,
.benefit-carousel {
  margin-right: -250px;

  @media (min-width: theme('screens.lg')) {
    margin-right: -115px;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    div {
      vertical-align: bottom;
    }
  }

  .slick-slide {
    padding-right: 20px;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-next {
    right: 265px;

    @media (min-width: theme('screens.lg')) {
      right: 130px;
    }
  }
  .slick-prev {
    right: 335px;
    left: auto;

    @media (min-width: theme('screens.lg')) {
      right: 200px;
    }
  }

  .slick-disabled {
    color: #afafaf;
    @apply text-base;
    padding: 10px;
    bottom: -21px;

    &.slick-next {
      right: 280px;

      @media (min-width: theme('screens.lg')) {
        right: 145px;
      }
    }
  }
}

.image-carousel {
  .slick-disabled {
    color: #afafaf;
    @apply text-base;
    padding: 10px;
    bottom: -21px;
  }
}

.carousel-modal {
  .slick-arrow {
    top: 45%;
    bottom: auto;
    transform: translate(0, -50%);

    &.slick-prev {
      left: -0.75rem;
      right: auto;
    }

    &.slick-next {
      right: -0.75rem;
      left: auto;
    }
  }

  img {
    max-height: 80vh;
    object-fit: contain;
  }
}

.blog-carousel {
  .slick-arrow {
    bottom: -26px;

    &.slick-disabled {
      bottom: -19px;
    }
  }
}

.narrow-carousel {
  @media (min-width: theme('screens.lg')) {
    margin-right: -215px;

    .slick-next {
      right: 230px;
    }

    .slick-prev {
      right: 300px;
    }

    .slick-disabled {
      &.slick-next {
        right: 245px;
      }
    }

    .teaser {
      .text {
        padding: 25px 25px 15px;

        .heading {
          @apply text-sm;
        }

        .intro {
          @apply text-xs;
        }
      }
    }
  }
}

.carousel-single-item {
  @media (min-width: theme('screens.lg')) {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .slick-track {
      margin-left: 0 !important;
    }
  }
}
