@import '../../../styles/variables';

.figure {
  margin-top: 40px;
  margin-bottom: 40px;
}

.figcaption {
  @media (min-width: theme('screens.lg')) {
    padding-left: 75px;
  }
}
