@mixin underline-left-to-right($color: $primary) {
  color: $color;
  text-decoration: none;
  border-bottom: none;
  background: linear-gradient(to bottom, $color 0%, $color 100%);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 0px 2px;
  transition: background-size 0.2s;
  padding-bottom: 2px;

  .group:hover &,
  &:hover {
    color: $color;
    background-size: 100% 2px;
  }
}

@mixin underline-bottom-to-top(
  $color: $primary,
  $hoverColor: $white,
  $backgroundColor: $primary
) {
  color: $color;
  text-decoration: none;
  border-bottom: none;
  background: linear-gradient(
    to bottom,
    $backgroundColor 0%,
    $backgroundColor 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 1px;
  transition: background-size 0.2s, color 0.2s;
  padding-bottom: 2px;

  .group:hover &,
  &:hover {
    color: $hoverColor;
    text-decoration: none;
    background-size: 2px 100%;
  }
}
